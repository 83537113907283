import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useJoinMeeting from '../../hooks/participant/useJoinMeeting';
import CustomInput from '../../Components/Input/CustomInput';
import { backgroundColor } from '../../data/colors';
import '../../assets/scss/pages/joinmeeting.scss'


const JoinMeeting = () => {

    const { joinRoomDetails, loading, error, handleChange, joinMeeting } = useJoinMeeting();

    return (
        <div className='join-meeting-wrapper-div'>
            <div className='flex-wrapper-div'>

                <div className='title-div'>ABRIL kids</div>

                <div className='textfield-wrapper-div'>

                    <CustomInput label="Enter Access Code (ingresar código de acceso)" style={{ margin: '15px 0' }}
                        name='meeting_id' value={joinRoomDetails.meeting_id} onChange={handleChange} error={error.meeting_id}
                    />

                    <CustomInput label="Enter Email Address (ingresar correo electrónico)" style={{ margin: '15px 0' }}
                        name='participant_name' value={joinRoomDetails.participant_name} onChange={handleChange} error={error.participant_name}
                    />

                </div>

                <div className='join-button-wrapper-div'>
                    <div>
                        <button className='join-button' onClick={joinMeeting}>
                            {loading ? <CircularProgress size={24} style={{ color: backgroundColor }} /> : 'Go to the STAGE (ir al ESCENARIO)'}
                        </button>

                        <p className='response-error-message'>
                            {error.errorMessage || ''}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default JoinMeeting;
