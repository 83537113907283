import { useState } from "react";
import { server_ip } from "../../data/Constant";
import validateObject from "../../utils/validateObject ";

const useRequest = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const responseValidator = (response) => {
        setLoading(false);

        if (response.status) {

            setError({});
            return response?.data || true

        } else {

            if (response?.error) {
                console.error(response.error)

            } else if (response?.message) {

                setError({ errorMessage: response.message });
            }

        }
    }

    const checkObject = (obj) => {
        const errorObject = validateObject(obj);

        if (Object.keys(errorObject).length === 0) {
            setError({})
            return false
        } else {
            setError({ ...errorObject })
            return true
        }
    };

    const getRequest = async (URL) => {
        setLoading(true);

        const request = await fetch(server_ip + URL,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer `,
                }
            })

        const response = await request.json();
        const validateResponse = responseValidator(response);
        return validateResponse
    }

    const postRequest = async (URL, bodyData) => {
        setLoading(true);

        const request = await fetch(server_ip + URL,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer `,
                },
                body: JSON.stringify(bodyData)
            })

        const response = await request.json();
        const validateResponse = responseValidator(response);
        return validateResponse
    }

    return {
        loading,
        error,
        checkObject,
        getRequest,
        postRequest
    }

}

export default useRequest