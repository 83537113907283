import React from 'react';
import Fab from '@mui/material/Fab';
import { yellowColor } from '../../data/colors';

const fabStyle = {
    margin: '0 5px',
    backgroundColor: yellowColor,
    "&:hover": {
        backgroundColor: '#ffea00'
    }
}

const CustomFab = (props) => {
    return (
        <Fab
            // aria-label="toggle Video"
            size="medium"
            sx={fabStyle}
            {...props}
        >
            {props.children}
        </Fab>
    )
}

export default CustomFab
