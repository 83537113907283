import { useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import uniqid from 'uniqid';
import { nanoid } from 'nanoid';
import useRequest from '../request/useRequest';
import useContextProvider from '../context/useContextProvider';

const useCreateMeeting = () => {

    const { createRoomDetails, setCreateRoomDetails, setAdminAuthorization, } = useContextProvider();

    const { loading, error, checkObject, postRequest } = useRequest();

    const navigate = useNavigate();


    const handleChange = (e) => {
        const { value, name } = e.target
        setCreateRoomDetails(prev => { return { ...prev, [name]: value } })
    }

    const createMeeting = async () => {
        const objectIsValid = checkObject(createRoomDetails);
        if (!objectIsValid) {
            const response = await postRequest('/jitsi/post/meeting_details', createRoomDetails);            
            if (response) {
                setAdminAuthorization(true)
                setCreateRoomDetails(prev => { return { ...prev, database_id: response.database_id } })
                navigate("/a-m")
            }
        }
    }

    const generateMeetingId = () => {
        const meeting_id = uniqid('meet_') + nanoid()
        setCreateRoomDetails(prev => {
            return { ...prev, meeting_id: meeting_id }
        })
    }

    return {
        createRoomDetails,
        loading,
        error,
        handleChange,
        createMeeting,
        generateMeetingId,
    }
}

export default useCreateMeeting
