import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import CreateMeeting from './pages/Admin_Screen/CreateMeeting';
import AdminScreen from './pages/Admin_Screen/AdminScreen';
import JoinMeeting from './pages/Participant_Screen/JoinMeeting';
import ParticipantScreen from './pages/Participant_Screen/ParticipantScreen';
import useContextProvider from './hooks/context/useContextProvider';

const App = () => {

  const { adminAuthorization, participantAuthorization } = useContextProvider()

  const adminIsAuth = (component) => {
    if (adminAuthorization) {
      return component
    } else {
      return <Navigate to='/create-meeting' />
    }
  }

  const participantIsAuth = (component) => {
    if (participantAuthorization) {
      return component
    } else {
      return <Navigate to='/join-meeting' />
    }
  }

  return (
    <Routes>
      <Route path="/create-meeting" element={<CreateMeeting />} />
      <Route path="/a-m" element={adminIsAuth(<AdminScreen />)} />

      <Route path="/join-meeting" element={<JoinMeeting />} />
      <Route path="/p-m" element={participantIsAuth(<ParticipantScreen />)} />
    </Routes>
  )
}

export default App