import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useCreateMeeting from '../../hooks/admin/useCreateMeeting';
import CustomInput from '../../Components/Input/CustomInput';
import { backgroundColor } from '../../data/colors';
import '../../assets/scss/pages/createmeeting.scss';


const CreateMeeting = () => {

    const { createRoomDetails, loading, error, handleChange, createMeeting, generateMeetingId } = useCreateMeeting();

    useEffect(() => { generateMeetingId() }, [])

    return (
        <div className='create-meeting-wrapper-div'>
            <div className='flex-wrapper-div'>

                <div className='title-div'>Create New Meeting</div>

                <div className='textfield-wrapper-div'>
                    {/* <input type="text" id='checkfield'/> */}
                    <CustomInput label="Meeting Id" style={{ margin: '15px 0' }}
                        name='meeting_id' value={createRoomDetails.meeting_id} onChange={handleChange} error={error.meeting_id}
                    />

                    <CustomInput label="Admin Name" style={{ margin: '15px 0' }}
                        name='admin_name' value={createRoomDetails.admin_name} onChange={handleChange} error={error.admin_name}
                    />

                </div>

                <div className='create-button-wrapper-div'>
                    <div>
                        <button className='create-button' onClick={createMeeting}>
                            {loading ? <CircularProgress size={24} style={{ color: backgroundColor }} /> : 'Create Room'}
                        </button>

                        <p className='response-error-message'>
                            {error.errorMessage || ''}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CreateMeeting