import { useNavigate, } from 'react-router-dom';
import useRequest from '../request/useRequest';
import useContextProvider from '../context/useContextProvider';

const useJoinMeeting = () => {

    const { joinRoomDetails, setJoinRoomDetails, setParticipantAuthorization } = useContextProvider();

    const { loading, error, checkObject, getRequest } = useRequest();

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { value, name } = e.target
        setJoinRoomDetails(prev => { return { ...prev, [name]: value } })
    }

    const joinMeeting = async () => {
        const objectIsValid = checkObject(joinRoomDetails);
        if (!objectIsValid) {
            const response = await getRequest(`/jitsi/get/meeting_details/${joinRoomDetails.meeting_id}`);
            if (response) {
                setParticipantAuthorization(true);
                setJoinRoomDetails(prev => { return { ...prev, admin_name: response.admin_name } })
                navigate("/p-m")
            }
        }
    }

    return {
        joinRoomDetails,
        loading,
        error,
        handleChange,
        joinMeeting
    }
}

export default useJoinMeeting;