import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from "socket.io-client"
import { server_ip } from '../../data/Constant';
import useContextProvider from '../context/useContextProvider';

const useParticipantScreen = () => {

    const { joinRoomDetails } = useContextProvider();

    const navigate = useNavigate();

    const socket = io(server_ip);

    const [apiRef, setApiRef] = useState();
    const [audioIsSharing, setAudioIsSharing] = useState(false);
    const [participantVolume, setParticipantVolume] = useState(0.5);
    const [terminatingMeeting, setTerminatingMeeting] = useState(false);
    const volume = useRef(0.5);

    const toggleAudio = () => {
        setAudioIsSharing(!audioIsSharing)
        apiRef.executeCommand('toggleAudio')
    }

    const getFullScreen = (e) => {
        const IframeRef = apiRef.getIFrame()
        if (IframeRef.requestFullscreen) {
            IframeRef.requestFullscreen();
        } else if (IframeRef.webkitRequestFullscreen) { /* Safari */
            IframeRef.webkitRequestFullscreen();
        } else if (IframeRef.msRequestFullscreen) { /* IE11 */
            IframeRef.msRequestFullscreen();
        }
    }

    const ParticipantVolume = (e) => {
        const ParticipantsInfo = apiRef.getParticipantsInfo()
        console.log(ParticipantsInfo, "dddddd")
        for (let obj of ParticipantsInfo) {
            if (obj.displayName !== joinRoomDetails.participant_name && obj.displayName !== joinRoomDetails.admin_name) {
                apiRef.executeCommand('setParticipantVolume', obj.participantId, e.target.value)
            }
        }
        volume.current = e.target.value
        setParticipantVolume(e.target.value)
        console.log(participantVolume, "lll")
    }

    const ParticipantVolumeSocket = (e) => {
        const ParticipantsInfo = apiRef.getParticipantsInfo()
        console.log(ParticipantsInfo, "dddddd")
        for (let obj of ParticipantsInfo) {
            if (obj.displayName !== joinRoomDetails.participant_name && obj.displayName !== joinRoomDetails.admin_name) {
                apiRef.executeCommand('setParticipantVolume', obj.participantId, e)
            }
        }
        volume.current = e
        setParticipantVolume(e)
        console.log(participantVolume, "lll")
    }

    socket.on("new_volume", (e) => {
        try {
            ParticipantVolumeSocket(e)
        }
        catch (e) {
            console.log(e)
        }

    })

    const hangUp = () => {
        setTerminatingMeeting(true)
        setTimeout(() => {
            apiRef.executeCommand('hangup')
            navigate("/join-meeting")
        }, 3000)
    }

    return {
        joinRoomDetails,
        apiRef, setApiRef,
        audioIsSharing, setAudioIsSharing,
        participantVolume, setParticipantVolume,
        terminatingMeeting, setTerminatingMeeting,
        volume,
        toggleAudio,
        getFullScreen,
        ParticipantVolume,
        ParticipantVolumeSocket,
        hangUp,
    }
}

export default useParticipantScreen
