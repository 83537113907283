import { createContext, useState } from 'react';

export const Context = createContext({})

const ContextProvider = ({ children }) => {
    const [adminAuthorization, setAdminAuthorization] = useState(false);
    const [createRoomDetails, setCreateRoomDetails] = useState({ meeting_id: '', admin_name: '', database_id: 'null' });
    const [participantAuthorization, setParticipantAuthorization] = useState(false);
    const [joinRoomDetails, setJoinRoomDetails] = useState({ meeting_id: '', admin_name: 'dummy', participant_name: '' });

    // console.log(createRoomDetails, 'createRoomDetails')
    // console.log(joinRoomDetails, 'joinRoomDetails')

    return (
        <Context.Provider value={{
            adminAuthorization, setAdminAuthorization,
            createRoomDetails, setCreateRoomDetails,
            participantAuthorization, setParticipantAuthorization,
            joinRoomDetails, setJoinRoomDetails,
        }}>
            {children}
        </Context.Provider>
    )
}
export default ContextProvider