import React, { useEffect, useState } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import { MdVideocam, MdVideocamOff, MdKeyboardVoice, MdVoiceOverOff, MdOutlineFullscreen, MdVolumeOff, MdVolumeUp, MdCallEnd } from "react-icons/md";
import { BsMicMuteFill } from "react-icons/bs";
import { Domain_name } from '../../data/Constant';
import CustomFab from '../../Components/Button/CustomFab';
import { yellowColor } from '../../data/colors';
import useAdminScreen from '../../hooks/admin/useAdminScreen';
import '../../assets/scss/pages/adminscreen.scss';

const AdminScreen = () => {

    const {
        createRoomDetails, apiRef, setApiRef, videoIsSharing, audioIsSharing,
        participantsIdArray, setParticipantIdArray, participantVolume, terminatingMeeting,
        toggleVideo, toggleAudio, pinScreen, ParticipantVolume, muteEveryone, setFullScreen, hangUp, } = useAdminScreen();

    useEffect(() => {
        if (participantsIdArray.length > 0) {
            participantsIdArray.forEach(element => {
                apiRef.executeCommand('setParticipantVolume', element, participantVolume)
            })
        }
    }, [participantsIdArray])

    return (
        <div className='admin-screen-container-wrapper-div'>
            <div className='admin-flex-wrapper-div'>

                <div className='admin-screen-container-div'>

                    <JitsiMeeting
                        domain={Domain_name}
                        roomName={createRoomDetails.meeting_id}
                        userInfo={{ displayName: createRoomDetails.admin_name }}

                        configOverwrite={{
                            startWithAudioMuted: true,
                            startWithVideoMuted: true,
                            prejoinPageEnabled: false,
                            /* filmstrip option is remove from toolbar because it always show filmstrip and does not allow to hide filmstrip forcefully  */
                            toolbarButtons: [
                                'camera', 'chat', 'closedcaptions', 'desktop', 'dock-iframe', 'download',
                                'embedmeeting', 'etherpad', 'feedback', 'fullscreen', 'help', 'highlight',
                                'invite', 'linktosalesforce', 'livestreaming', 'microphone', 'noisesuppression',
                                'participants-pane', 'profile', 'raisehand', 'recording', 'security', 'select-background',
                                'settings', 'shareaudio', 'sharedvideo', 'shortcuts', 'stats', 'tileview', 'toggle-camera',
                                'undock-iframe', 'videoquality',
                            ],
                        }}

                        getIFrameRef={iframeRef => {
                            iframeRef.style.width = "100%";
                            iframeRef.style.height = "100%";
                        }}

                        onApiReady={(externalApi) => {
                            setTimeout(() => {
                                externalApi.executeCommand('toggleFilmStrip');
                                externalApi.executeCommand('setFollowMe', true)
                            }, 15000);
                            setTimeout(() => { pinScreen(externalApi) }, 30000)
                            setApiRef(externalApi)

                            externalApi.addListener('participantJoined', ({ id, displayName }) => {
                                setParticipantIdArray(prev => {
                                    return [...prev, id]
                                })
                            })

                            externalApi.addListener('participantLeft', ({ id }) => {
                                setParticipantIdArray(prev => {
                                    return prev.filter((value, index, array) => {
                                        return value !== id
                                    })
                                })
                            })
                        }}

                    />
                </div>

                <div className='option-buttons-wrapper-div'>
                    {/* <CustomFab aria-label="toggle Video" disabled={terminatingMeeting} onClick={toggleVideo} >
                        {videoIsSharing ? <MdVideocam size={20} /> : <MdVideocamOff size={20} />}
                    </CustomFab> */}

                    {/* <CustomFab aria-label="toggle Audio" disabled={terminatingMeeting} onClick={toggleAudio} >
                        {audioIsSharing ? <MdKeyboardVoice size={20} /> : <BsMicMuteFill size={18} />}
                    </CustomFab> */}

                    <CustomFab aria-label="mute Everyone" disabled={terminatingMeeting} onClick={muteEveryone} >
                        <MdVoiceOverOff size={19} />
                    </CustomFab>

                    {/* <CustomFab aria-label="set FullScreen" disabled={terminatingMeeting} onClick={setFullScreen} >
                        <MdOutlineFullscreen size={23} />
                    </CustomFab> */}

                    <div className='slider-div'>
                        <MdVolumeOff size={23} style={{ color: yellowColor, margin: '0 10px 0 2px' }} />
                        <Slider
                            size='small'
                            aria-label="Participant Volume"
                            disabled={terminatingMeeting}
                            value={participantVolume}
                            min={0} max={1} step={0.1}
                            onChange={ParticipantVolume}
                            sx={{ width: 150, color: yellowColor }}
                        />
                        <MdVolumeUp size={23} style={{ color: yellowColor, margin: '0 0 0 7px' }} />
                    </div>

                    <CustomFab aria-label="Call End" disabled={terminatingMeeting} onClick={hangUp} >
                        {terminatingMeeting ? <CircularProgress size={20} style={{ color: '#ffff00' }} /> : <MdCallEnd size={20} />}
                    </CustomFab>
                </div>

            </div>
        </div>
    )
}

export default AdminScreen;