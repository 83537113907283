const validateObject = (obj) => {
    //check the length of object if length is 0 then the object is null it return undefined.
    if (Object.keys(obj).length !== 0) {

        let errorFieldsName = [];

        //iterate on every key of object and check the value of key is not undefined, null or empty string. If found any undefine value then the key is pushed into array
        for (let key in obj) {

            if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
                errorFieldsName.push(key);
            }
        }

        const errorObject = Object.fromEntries(
            errorFieldsName.map(fieldName => [fieldName, true])
        )

        return errorObject;

    }
    else {
        return undefined;
    }
}

export default validateObject 
