import React from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk'
import Fab from '@mui/material/Fab';
// import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import { MdKeyboardVoice, MdOutlineFullscreen, /* MdVolumeOff, MdVolumeUp, */ MdCallEnd } from "react-icons/md";
import { BsMicMuteFill } from "react-icons/bs";
import { Domain_name } from '../../data/Constant';
import useParticipantScreen from '../../hooks/participant/useParticipantScreen';
import '../../assets/scss/pages/participantscreen.scss'
import CustomFab from '../../Components/Button/CustomFab';


const ParticipantScreen = () => {

    const {
        joinRoomDetails, setApiRef, audioIsSharing, participantVolume, terminatingMeeting, volume,
        toggleAudio, getFullScreen, hangUp, } = useParticipantScreen();

    return (
        <div className='participant-screen-container-wrapper-div'>
            <div className='flex-wrapper-div'>

                <div className='participant-screen-container-div'>
                    <JitsiMeeting
                        domain={Domain_name}
                        roomName={joinRoomDetails.meeting_id}
                        userInfo={{ displayName: joinRoomDetails.participant_name }}

                        configOverwrite={{
                            startWithAudioMuted: true,
                            startWithVideoMuted: true,
                            prejoinPageEnabled: false,
                            disableSelfView: true,
                            toolbarButtons: [],
                            notifications: ['notify.mutedRemotelyTitle'],
                        }}

                        getIFrameRef={iframeRef => {
                            iframeRef.style.width = '100%';
                            iframeRef.style.height = '100%';
                        }}

                        onApiReady={(externalApi) => {
                            /* the command is working showing filmstrip because the filmstrip is automatically
                             hide in the toolbar option that why it show the filmstrip instead of hiding*/
                            // setTimeout(() => { externalApi.executeCommand('toggleFilmStrip'); }, 10000);

                            setApiRef(externalApi)

                            externalApi.addListener('participantJoined', ({ id, displayName }) => {
                                externalApi.executeCommand('setParticipantVolume', id, volume.current)
                            })

                            externalApi.addListener('videoConferenceJoined', ({ roomName, id, displayName, avatarURL, breakoutRoom }) => {
                                const getParticipantsInfoApi = setInterval(() => {
                                    const ParticipantsInfo = externalApi.getParticipantsInfo()
                                    if (ParticipantsInfo.length >= 2) {
                                        clearInterval(getParticipantsInfoApi)
                                        for (let obj of ParticipantsInfo) {
                                            if (obj.displayName !== joinRoomDetails.participant_name && obj.displayName !== joinRoomDetails.admin_name) {
                                                externalApi.executeCommand('setParticipantVolume', obj.participantId, participantVolume)
                                            }
                                            if (obj.displayName === joinRoomDetails.admin_name) {
                                                externalApi.executeCommand('setParticipantVolume', obj.participantId, 1)
                                            }
                                        }
                                    }
                                }, 1500)
                            })

                        }}

                    />
                </div>

                <div className='option-buttons-wrapper-div'>

                    <CustomFab aria-label="add" disabled={terminatingMeeting} onClick={toggleAudio} >
                        {audioIsSharing ? <MdKeyboardVoice size={20} /> : <BsMicMuteFill size={18} />}
                    </CustomFab>

                    <CustomFab aria-label="add" disabled={terminatingMeeting} onClick={getFullScreen} >
                        <MdOutlineFullscreen size={23} />
                    </CustomFab>

                    <div className='slider-div'>
                        {/* <Slider aria-label="Participant Volume" size='small' disabled={terminatingMeeting}
                            value={participantVolume} min={0} max={1} step={0.1} onChange={ParticipantVolume} sx={{ width: 150, color: '#FF008E' }}
                        /> */}
                    </div>

                    <CustomFab aria-label="add" disabled={terminatingMeeting} onClick={hangUp}>
                        {terminatingMeeting ? <CircularProgress size={20} /> : <MdCallEnd size={20} />}
                    </CustomFab>
                </div>

            </div>
        </div>
    )
}

export default ParticipantScreen
