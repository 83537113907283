import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { yellowColor } from '../../data/colors';

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: yellowColor,
    },
    '& label': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FF008E',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: yellowColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: yellowColor,
        },
        '& .MuiOutlinedInput-input': {
            color: 'white',
            // "user-select": 'text',
            // "-webkit-user-select": 'text',
            // "-khtml-user-select": 'text',
            // "-moz-user-select": 'text',
            // "-ms-user-select": 'text',
            userSelect: 'text',
            WebkitUserSelect: 'text',
            KhtmlUserSelect: 'text',
            MozUserSelect: 'text',
            msUserSelect: 'text',
        }
    },
});


const CustomInput = (props) => {
    return (
        <CustomTextField
            fullWidth
            id="standard-basic"
            autoComplete='off'
            {...props}
        />

    )
}

export default CustomInput