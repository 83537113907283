
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from "socket.io-client";
import { server_ip } from '../../data/Constant';
import useRequest from '../request/useRequest';
import useContextProvider from '../context/useContextProvider';

const useAdminScreen = () => {

    const { createRoomDetails } = useContextProvider();

    const { getRequest } = useRequest();

    const navigate = useNavigate();

  //  const socket = io(server_ip);
    const socket = io(server_ip, {
   withCredentials: true,
  secure:true,
  transports:["websocket"],
  upgrade:true
});
    const [apiRef, setApiRef] = useState({});
    const [videoIsSharing, setVideoIsSharing] = useState(false);
    const [audioIsSharing, setAudioIsSharing] = useState(false);
    const [participantsIdArray, setParticipantIdArray] = useState([]);
    const [participantVolume, setParticipantVolume] = useState(0.5);
    const [terminatingMeeting, setTerminatingMeeting] = useState(false);

    const toggleVideo = () => {
        setVideoIsSharing(!videoIsSharing)
        apiRef.executeCommand('toggleVideo')
    }

    const toggleAudio = () => {
        setAudioIsSharing(!audioIsSharing)
        apiRef.executeCommand('toggleAudio')
    }

    const ParticipantVolume = (e) => {
        setParticipantVolume(e.target.value)
        socket.emit("change_volume", e.target.value)
        if (participantsIdArray.length > 0) {
            participantsIdArray.forEach(element => {
                apiRef.executeCommand('setParticipantVolume', element, e.target.value)
            })
        }
    }

    const pinScreen = (ref) => {
        const ParticipantsInfo = ref.getParticipantsInfo();
        for (let obj of ParticipantsInfo) {
            if (obj.displayName === createRoomDetails.admin_name) {
                ref.pinParticipant(obj.participantId)
            }
        }       
    }

    const muteEveryone = () => { apiRef.executeCommand('muteEveryone', 'audio') }

    const setFullScreen = (e) => {
        const IframeRef = apiRef.getIFrame()
        if (IframeRef.requestFullscreen) {
            IframeRef.requestFullscreen();
        } else if (IframeRef.webkitRequestFullscreen) { /* Safari */
            IframeRef.webkitRequestFullscreen();
        } else if (IframeRef.msRequestFullscreen) { /* IE11 */
            IframeRef.msRequestFullscreen();
        }
    }

    const hangUp = async () => {
        setTerminatingMeeting(true)
        for (let index = 0; index <= participantsIdArray.length; index++) {
            if (index < participantsIdArray.length) {
                apiRef.executeCommand('kickParticipant', participantsIdArray[index])
            }
            if (index === participantsIdArray.length) {
                const response = await getRequest(`/jitsi/delete/meeting_details/?meeting_id=${createRoomDetails.meeting_id}&id=${createRoomDetails.database_id}`);
                if (response) {
                    setTimeout(() => {
                        apiRef.executeCommand('hangup')
                        navigate("/create-meeting")
                    }, 5000)
                }
            }
        }
    }

    return {
        createRoomDetails,
        apiRef, setApiRef,
        videoIsSharing, setVideoIsSharing,
        audioIsSharing, setAudioIsSharing,
        participantsIdArray, setParticipantIdArray,
        participantVolume, setParticipantVolume,
        terminatingMeeting, setTerminatingMeeting,
        toggleVideo,
        toggleAudio,
        pinScreen,
        ParticipantVolume,
        muteEveryone,
        setFullScreen,
        hangUp,
    }
}

export default useAdminScreen
